<template>
  <div class="nav-bar">
    <div class="container">
      <div class="container-full header">
        <slot name="left"><nav-bar-left /></slot>
        <slot name="right"><nav-bar-user :style="rightShow"/></slot>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarUser from 'components/navbar/NavBarUser'
import NavBarLeft from 'components/navbar/NavBarLeft'

export default {
  components: {
    NavBarUser,
    NavBarLeft
  },
  props: {
    rightShow: { default: 'display: block;' }
  }
}
</script>

<style scoped src="styles/components/navbar.css">
</style>
