<template>
  <div class="header-left">
    <nav-bar-logo />
    <nav-bar-menu />
  </div>
</template>

<script>
import NavBarLogo from 'components/navbar/NavBarLogo'
import NavBarMenu from 'components/navbar/NavBarMenu'

export default {
  components: {
    NavBarLogo,
    NavBarMenu
  }
}
</script>

<style scoped src="styles/components/navbarleft.css">
</style>
