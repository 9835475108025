<template>
  <div class="logo">
    <!-- <img src='../image.png' style="width: 100%;"> -->
    <img :src="$store.getters.getSiteInfo.logal" style="width: auto;" />
    <!--<h2 :style="font">{{$store.getters.getSiteInfo.name}}</h2> -->
  </div>
</template>

<script>
export default {
  props: {
    font: { default: 'font-size: 34px;' }
  }
}
</script>

<style scoped src="styles/components/navbarlogo.css"></style>
